import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 제휴카드 3종 구성. 데이터 추가 제공에 1개월 무료 구독 파격 프로모션까지",
  "| “알뜰폰 향한 관심 실감, 다양한 제휴 늘려 고객 혜택 돌려드릴 것”",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/15/RtDeqfqN.jpeg" alt="Figure1" />
      </div>
      <div className="desc">△스테이지파이브가 이달 초까지 구성한 카드 제휴 혜택</div>
    </div>
    <p>
      <br />
      알뜰폰(MVNO)이 제휴 서비스와 프로모션으로 중무장하며 진화하고 있다. ‘알뜰폰은 서비스가 약하다’는 편견을 깨려는 시도들이다.
      금년 3월 알뜰폰 요금제 4종을 출시한 스테이지파이브 역시 최근의 알뜰폰 수요를 실감하며 고객 혜택 강화를 위해 각종 프로모션에
      더해 카드 제휴를 늘려가고 있다.
      <br />
      <br />
      스테이지파이브가 최근까지 구성한 제휴 카드는 △롯데카드 Mobile x LOCA △하나카드 렌탈 플러스 △KB국민 알뜰폰 Hub Ⅱ 등 총
      3종이다. 모두 핀다이렉트 알뜰 유심 요금제를 자동이체 신청했을 시 청구할인 혜택이 돌아간다.
      <br />
      <br />
      롯데카드 ‘Mobile x LOCA’는 핀다이렉트 알뜰 유심 요금제를 자동이체 신청했을 때, 매달 최대 2만 원의 청구 할인을 받을 수 있다.
      가입상태를 13개월 이상 유지할 시, 통신요금 10% 할인 및 국내 롯데카드의 풍성한 가맹점 할인 혜택을 받을 수 있다.
      <br />
      <br />
      전월 이용 실적에 따라 40만 원 이상 충족 시엔 매달 1만 5천원의 할인, 70만 원 이상 충족 시엔 매달 2만 원의 할인이 적용 된다.
      롯데카드 신규 회원인 경우, 본인카드에 한해 1인 1회 연회비 캐시백 이벤트도 진행 중이다. (21년 12월 31일 이벤트 종료 예정)
      <br />
      <br />
      하나카드 ‘렌탈 플러스’ 역시 전월 이용 실적 30만 원 이상이면, 매달 1만 3천원의 통신료 할인이 적용된다. 최초 카드 사용
      등록일부터 다음 달 말일까지는 실적 관계 없이 할인 혜택을 제공한다.
      <br />
      <br />
      하나카드 렌탈 플러스의 장점은 온라인쇼핑 청구할인과 스타벅스 청구 할인을 함께 받을 수 있다는 것. 지난 달 실적이 30만원
      이상이면, 각각 3%(온라인쇼핑, 월 한도 5천원)와 5%(스타벅스, 월 한도 5천 원)의 청구 할인이 된다.
      <br />
      <br />
      KB국민카드의 ‘알뜰폰 HubⅡ’도 스테이지파이브 제휴 카드 중 하나다. 알뜰폰 요금 자동이체 시 할인혜택을 받을 수 있다. 전월
      이용금액 30만 원 이상일 경우, 통신료 1만 2천 원의 할인, 전월 이용금액 70만 원 이상인 경우는 통신료 1만 7천 원의 할인을 받을
      수 있다. 최초 카드 사용 등록일부터 다음 달 말일까지는 카드 실적에 관계 없이 1만 2천 원 기본 차감을 제공한다.
      <br />
      <br />
      스테이지파이브 관계자는 “알뜰폰을 일컫는 ‘효도폰’은 이제 옛말이 되었고, 고객에게 필요한 다양한 혜택을 이어주는 ‘효자’로
      거듭나고 있다. 다양한 제휴 서비스로 이용자 삶 곳곳에서 활용되는 서비스 제휴를 계속해서 선보일 것”이라고 전했다.
      <br />
      <br />
      한편, 스테이지파이브는 제휴 뿐만 아니라 프로모션에도 힘을 쏟고 있다. 지난 3월, 동급 초저가로 MVNO(알뜰폰) 5G 및 LTE 유심
      요금제(1.4GB~15GB) 4종을 1만 6천~2만 7천 원 구성으로 출시한 이래 꾸준히 가입자 상승세를 이어가고 있기 때문이다.
      <br />
      <br />
      스테이지파이브는 현재 4GB~150GB를 1년 혹은 평생 제공하는 데이터 추가 제공 프로모션과 1개월 통신 무료 구독의 파격 프로모션을
      함께 진행 중이다.
      <br />
      <br />
      더하여 신규고객의 70% 이상을 차지하는 MZ세대에 화답하는 의미로 7월 한정 7만 원 상당 명상앱 ‘코끼리’와 이색 콜라보를 통한
      ‘굿나잇 패키지’도 증정하고 있다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 7월 7일 즉시 배포 가능"
      pageInfo="*총 3매/ 첨부: 사진 4매"
      title="제휴서비스+프로모션으로 진화하는 알뜰폰, “카드 실적 따라 청구할인도 OK"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
